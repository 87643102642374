
<script>
import AvailableChip from '../components/AvailableChip.vue';
export default {
    name: 'Desk',
    components: { AvailableChip },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        isAvailable: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        return {

        };
    }    
};
</script>

<template>
    <ion-item
        class="desk-booking-ion-item"
    >
        <div style="width: 100%;">
            <div class="desk-item">
                <ion-label>{{ data.display_name }}</ion-label>
                <AvailableChip :available="isAvailable" />
            </div>
            <div class="desk-building" v-if="data.building_name">{{ data.building_name }}</div>
            <div class="desk-floor" v-if="data.floor">Floor {{ data.floor }}</div>
        </div>
    </ion-item>
</template>

<style lang="scss">
.desk-booking-ion-item {
    --background: #181818;
    --border-color: transparent;
    --border-radius: 7px;
    --border-width: 0px;
    --min-height: 80px;
    margin: 3px 0;

    .desk-item {
        display: flex;
        font-weight: 700;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
    }

    .desk-building, .desk-floor {
        font-size: 12px;
        line-height: 1.4;
    }
}

</style>