import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_select, {
      interface: "popover",
      modelValue: _ctx.defaultOption,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.defaultOption) = $event)),
      onIonChange: _ctx.onSortOptionChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortOptions, (option) => {
          return (_openBlock(), _createBlock(_component_ion_select_option, {
            key: option.id,
            value: option.id,
            textContent: _toDisplayString(option.name)
          }, null, 8, ["value", "textContent"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onIonChange"]),
    _createVNode(_component_ion_button, {
      onClick: _ctx.onToggleDir,
      class: "ion-margin"
    }, {
      default: _withCtx(() => [
        (_ctx.defaultSort === 'asc')
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              slot: "start",
              icon: _ctx.arrowUp,
              size: "large"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (_ctx.defaultSort === 'desc')
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 1,
              slot: "start",
              icon: _ctx.arrowDown,
              size: "large"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}