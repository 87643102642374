
<script>
export default {
    name: 'AvailableChip',
    props: {
        available: {
            type: Boolean,
            default: false,
        }
    }
};
</script>

<template>
    <div :class="`chip ${available ? 'available' : ''}`">{{ available ? 'available' : 'booked' }}</div>
</template>

<style lang="scss" scoped>
.chip {
    $availableColor: #00e072;
    $bussyColor: #e00000;
    background: rgba($bussyColor, .3);;
    border-radius: 20px;
    border: 1px solid $bussyColor;
    color: $bussyColor;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    padding: 5px 9px;
    text-transform: uppercase;

    &.available {
        background: rgba($availableColor, .3);
        border: 1px solid $availableColor;
        color: $availableColor;
    }
}
</style>