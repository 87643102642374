
import { defineComponent, ref, watch } from 'vue';
import { arrowUp, arrowDown } from 'ionicons/icons';

export default defineComponent({
    name: 'Sort',
    props: ['resetSort'],
    emits: ['on-sort'],
    setup(prop, { emit }) {
        const defaultSort = ref('asc');
        const defaultOption = ref('name');
        const sortOptions = [
            {
                id: 'status',
                name: 'Status'
            }, {
                id: 'name',
                name: 'Name'
            }
        ];

        watch(prop, (cur: any) => {
            if (cur.resetSort) {
                emit('on-sort', {
                    sortDir: defaultSort.value,
                    sortOption: defaultOption.value
                });
            }
        });

        const onSortOptionChange = () => {
            emit('on-sort', {
                sortDir: defaultSort.value,
                sortOption: defaultOption.value
            });
        };

        const onToggleDir = () => {
            if (defaultSort.value === 'asc') {
                defaultSort.value = 'desc';
                emit('on-sort', {
                    sortDir: 'desc',
                    sortOption: defaultOption.value
                });
            } else {
                defaultSort.value = 'asc';
                emit('on-sort', {
                    sortDir: 'asc',
                    sortOption: defaultOption.value
                });
            }
        };

        return {
            arrowUp,
            arrowDown,
            defaultSort,
            defaultOption,
            sortOptions,
            onToggleDir,
            onSortOptionChange
        };
    },
});
