
import { computed } from 'vue';
import useDeskBooking from '@/composables/useDeskBooking';
import useNavigation from '@/composables/useNavigation';
import { close } from 'ionicons/icons';

export default {
    name: 'DeskBookingFilter',
    setup() {
        const { gotoDeskBookingSearch } = useNavigation();

        const {
            buildlingsList,
            deskSpaces,
            initialStartTime,
            initialEndTime,
            deskFiltersModel,
            floorsInSelectedBuilding,
            isValidTimeSlot,
            deskSpacesFiltered,
            onFiltersTimeUpdate,
            timeGapText,
            zonesInSelectedBuilding,
            isAvailable,
            deskSpaceEvents,
        } = useDeskBooking();

        const totalDesksAvailable = computed<number>(() => {
            return deskSpacesFiltered.value.filter(d => isAvailable(d.id)).length;
        });

        return {
            buildlingsList,
            deskFiltersModel,
            deskSpaces,
            floorsInSelectedBuilding,
            initialEndTime,
            initialStartTime,
            isValidTimeSlot,
            deskSpacesFiltered,
            onFiltersTimeUpdate,
            timeGapText,
            gotoDeskBookingSearch,
            close,
            zonesInSelectedBuilding,
            isAvailable,
            totalDesksAvailable,
            deskSpaceEvents,
        };
    },
};
