
import { defineComponent, onMounted, ref } from 'vue';
import Desk from '@/components/Desk.vue'; 
import { options, arrowUp, arrowDown } from 'ionicons/icons';
import useDeskBooking from '@/composables/useDeskBooking'; 
import useNavigation from '@/composables/useNavigation'; 
import Sort from '@/components/Sort.vue';
import DeskBookingFilter from '@/views/DeskBookingFilters.vue';

export default defineComponent({
    name: 'DeskBookingSearch',
    components: {
        Desk,
        Sort,
        DeskBookingFilter
    },
    setup() {
        const { gotoDeskDetails } = useNavigation();
        const { 
            deskSpaces,
            deskFiltersModel,
            deskSpacesFiltered,
            isAvailable,
            sortList,
            resetInitialTime,
            resetSort,
            syncDeskEvents,
        } = useDeskBooking();

        const isOpenRef = ref(true);
        const showFilter = (state: boolean) => {
            isOpenRef.value = state;
            resetSort.value = false;
        };

        onMounted(() => {
            resetInitialTime();
            syncDeskEvents();
        });

        return {
            deskSpaces,
            deskSpacesFiltered,
            deskFiltersModel,
            gotoDeskDetails,
            isAvailable,
            options,
            arrowUp,
            arrowDown,
            sortList,
            showFilter,
            isOpenRef,
            resetSort
        };
    },
});
